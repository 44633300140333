<template>
  <app-event event-id="alfa-2022" auth="login-only">
    <template v-slot:logo>
      <div
        style="
          padding: 8px;
          border-radius: 8px;
          background-color: #fffc;
          margin: 16px;
        "
        class="d-flex flex-column align-center"
      >
        <v-img
          src="https://cdn.your-quest.com/alfa-2022/theme/logo.png"
          class="ma-4"
          style="width: 50%"
        />
      </div>
    </template>
  </app-event>
</template>

<script>
import AppEvent from "@/components/event/Event.vue";

export default {
  name: "AppPageEvent",
  components: {
    AppEvent,
  },
};
</script>
